const gray = {
  gray025: '#fcfcfc',
  gray075: '#f5f5f5',
  gray100: '#eeeeee',
  gray200: '#cccccc',
  gray850: '#333333'
};

const magma = {
  magma020: '#FECE22',
  magma040: '#FCA22D',
  magma060: '#F97537',
  magma080: '#F74942',
  magma100: '#CC1A42'
};

const marine = {
  marine020: '#50E3C2',
  marine040: '#50B7C7',
  marine060: '#518BCB',
  marine080: '#515ED0',
  marine100: '#5132D4'
};

const meadow = {
  meadow020: '#D0EE4C',
  meadow040: '#A7CB55',
  meadow060: '#7FA95F',
  meadow080: '#568668',
  meadow100: '#2D6371'
};

export const colors = {
  ...gray,
  ...magma,
  ...marine,
  ...meadow,
  black: '#000',
  failure: magma.magma080,
  link: '#0a62c3',
  success: meadow.meadow080,
  white: '#ffffff'
};
