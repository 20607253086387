import { A } from 'hookrouter';
import * as React from 'react';

export interface IHomeProps {
    routes: Array<{route: string; label: string; version: number}>;
}

const ListItem = (props) => (
    <li className='text-xl mb-4'>
        <A href={props.route} key={props.route}>{props.label}</A>
    </li>
);

const List = (props) => {
    const list = props.v1.length && props.v1.length
        ? <DoubleList v1={props.v1} v2={props.v2} />
        : <SingleList list={props.v1.length ? props.v1 : props.v2} />;

    return <div className='mt-12'>{list}</div>;
};

const SingleList = (props) => (
    <div className='rounded bg-white p-4 shadow-2xl h-full'>
        <div className='text-2xl mt-4 mb-6'>{props.title}</div>
        <ul>
            {props.list.map((route) => <ListItem {...route} />)}
        </ul>
    </div>
);

const DoubleList = (props) => (
    <div className='flex flex-wrap -mx-4'>
        <div className='px-4 w-full mb-6 lg:w-1/2'>
            <SingleList list={props.v1} title='Legacy' />
        </div>
        <div className='px-4 w-full mb-6 lg:w-1/2'>
            <SingleList list={props.v2} title='v2' />
        </div>
    </div>
);

export const Home = (props: IHomeProps) => {
    const v1 = props.routes.filter((route) => route.version === 1);
    const v2 = props.routes.filter((route) => route.version === 2);

    return (
        <div>
            <div className='bg-img absolute w-full z-0' />
            <div className='main container mx-auto text-center pt-12 relative z-10 '>
                <h1 className='text-4xl pt-8 text-white font-bold'>Armor API Docs</h1>

                {/*<input type="text" className="search-xl form-control" placeholder="Search" />*/}

                <List v1={v1} v2={v2}/>
            </div>
        </div>
    );
};
