import { useRoutes } from 'hookrouter';
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { RedocStandalone } from 'redoc';

import { redocOptions } from '../models/redoc-options';
import { Home } from './Home';
import { Auth } from './Auth';
import { INavProps, Nav } from './Nav';
import { NotFound } from './NotFound';

import '../assets/styles/main.css';

const routes = [
    {label: 'Home', route: '/', version: null,  render: () => <Home routes={routes}/>},

    {label: 'Home', route: '/authentication', version: null,  render: () => <Auth />},

    {label: 'Account Management', route: '/account-management', version: 1, render: () => <RedocStandalone specUrl='swagger/v1/account-management/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Infrastructure', route: '/infrastructure', version: 1, render: () => <RedocStandalone specUrl='swagger/v1/infrastructure/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Security', route: '/security', version: 1, render: () => <RedocStandalone specUrl='swagger/v1/security/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Support', route: '/support', version: 1, render: () => <RedocStandalone specUrl='swagger/v1/support/swagger.json' options={redocOptions}></RedocStandalone>},

    {label: 'Accounts', route: '/accounts', version: 2, render: () => <RedocStandalone specUrl='/swagger/accounts/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Agent Management', route: '/agent-management', version: 2, render: () => <RedocStandalone specUrl='/swagger/agent-management/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Incident Management', route: '/incident-management', version: 2, render: () => <RedocStandalone specUrl='/swagger/incident-management/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Infrastructure Management', route: '/infrastructure-management', version: 2, render: () => <RedocStandalone specUrl='/swagger/infrastructure-management/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Compliance', route: '/compliance', version: 2, render: () => <RedocStandalone specUrl='/swagger/compliance/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Container Security', route: '/container-security', version: 2, render: () => <RedocStandalone specUrl='/swagger/container-security/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Log Management', route: '/log-management', version: 2, render: () => <RedocStandalone specUrl='/swagger/logs/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Notifications', route: '/notifications', version: 2, render: () => <RedocStandalone specUrl='/swagger/notifications/swagger.json' options={redocOptions}></RedocStandalone>},
    {label: 'Webhooks', route: '/webhooks', version: 2, render: () => <RedocStandalone specUrl='/swagger/webhooks/swagger.json' options={redocOptions}></RedocStandalone>}
];

const App = () => {
    // Build up the hook-router object from the routes
    const routeResult = useRoutes(routes.reduce((rts, domain) => {
        rts[domain.route] = domain.render;
        return rts;
    }, {}));

    const navProps: INavProps = {
        routes: routes.map(({label, route, version}) => {
            return {label, route, version};
        })
    };

    return (
        <div className='app'>
            <Nav routes={navProps.routes}/>
            {routeResult || <NotFound/>}
        </div>
    );
};

declare let module: object;

export default hot(module)(App);
