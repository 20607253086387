import { A, navigate, usePath } from "hookrouter";
import * as React from "react";

const logo = require("./../assets/img/armor_logo.svg");

export interface INavProps {
    routes: Array<{route: string; label: string; version?: number}>;
}

export const Nav = (props: INavProps) => {
    const current = usePath();
    const v1 = props.routes.filter((route) => route.version === 1);
    const v2 = props.routes.filter((route) => route.version === 2);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        navigate(event.target.value);
    };

    return (
        <div className="nav">
            <div className="flex">
                <A href={"/"}><img src={logo} alt="Armor" /></A>
                <select value={current} onChange={handleChange} className="ml-6 form-control">
                    <optgroup label='Legacy'>
                       {(v1.map((route) => <option value={route.route} key={route.route}>{route.label}</option>))}
                    </optgroup>
                    <optgroup label='v2'>
                       {(v2.map((route) => <option value={route.route} key={route.route}>{route.label}</option>))}
                    </optgroup>
                </select>
            </div>
        </div>
    );
};
