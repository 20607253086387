import * as React from 'react';

export const Auth = () => {
  return (
    <div>
      <div className='bg-img full-height absolute w-full z-0' />
      <div className='main container mx-auto pt-12 relative z-10 text-content'>
        <div className="content-container">
          <h1 className='text-4xl font-bold'>Authentication</h1>
          <p>Armor&trade; Service APIs support multiple authentication methods. In the API documentation,&nbsp;
            <strong>each resource defines which authentication methods are supported by that resource</strong>,
            denoted by one or more of these authorization schemes:
          </p>

          <h3 id="oauth2_scoped">OAuth2 (Scoped)</h3>
          <p>This authentication method uses the <code>Bearer</code> authorization scheme and requires
            both an ID token and a scoped access token. <strong>This is the preferred authentication
            method</strong> and all future APIs will support this method.</p>

          <div className="code-block">
            <label>Example</label>
            <code>
              curl https://sample.api.armor.com/resource -H "authorization: Bearer $ID_TOKEN,$ACCESS_TOKEN"
            </code>
          </div>

          <h3 id="oauth2">OAuth2</h3>
          <p>This authentication method also uses the <code>Bearer</code> authorization scheme but only
            requires an ID token.</p>

          <div className="code-block">
            <label>Example</label>
            <code>
              curl https://api.armor.com/resource -H "authorization: Bearer $ID_TOKEN"
            </code>
          </div>

          <h3 id="psk">Pre-Shared Key</h3>
          <p>This authentication method uses a custom <code>ARMOR-PSK</code> authorization scheme requiring
            the API Key ID and the request signature.</p>

          <div className="code-block">
            <label>Example</label>
            <code>
              curl https://api.armor.com/resource -H "authorization: ARMOR-PSK $API_KEY_ID,$REQUEST_SIGNATURE"
            </code>
          </div>

          <h2>JSON Web Tokens (JWTs)</h2>
          <p>The OAuth2 specification provides for both authentication and authorization to be asserted with
            the use of JSON Web Tokens or JWTs. Authentication is asserted with the use of an <strong>ID
            token</strong> and authorization is asserted with the use of an <strong>access token</strong>.
          </p>
          <p>The ID token asserts who you are and the access token asserts that you are permitted to take
            specific actions on specific resources. Both tokens are cryptographically signed, and that
            signature is validated on every request.
          </p>

          <h3>Requesting an ID Token</h3>
          <p>Authentication and ID token generation is handled by <a href="https://sts.armor.com/">sts.armor.com</a>.
            To authentication and receive an ID token, follow these steps:</p>

          <h4>Authenticate</h4>
          <p><span className="http-method post">POST</span> <code className="no-background">https://sts.armor.com/adfs/oauth2/authorize</code></p>

          <h5 className="request-summary">Query String Parameters</h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name first">
                  <span className="property-marker"></span>
                  response_type
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The expected response type. Always <code>id_token</code></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  response_mode
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The method by which the ID token will be returned. Always <code>form_post</code></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  client_id
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The relying party client identifier.</p>
                    <p>
                      <code>b2264823-30a3-4706-bf48-4cf80dad76d3</code><br />
                      Use this client by default – multi-factor authentication is required.<br />
                      <br />
                      <code>d467ba47-2382-44cd-8779-5fb9a3abf69b</code><br />
                      Use this client for service accounts and non-interactive sessions – does not require multi-factor authentication.
                    </p>
                    <p>
                      <em>Note that in order to use the non-interactive <code>client_id</code>, that
                      account must be explicitly excluded from the MFA process. Read more about&nbsp;
                      <a href="#disabling_mfa">Disabling Multi-Factor Authentication (MFA)</a> below.</em>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name last">
                  <span className="property-marker"></span>
                  redirect_uri
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The base URL of the API that will be used in this session. Example: <code>https://api.armor.com/</code></p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className="request-summary">Request Body Schema <span>application/x-www-form-urlencoded</span></h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name first">
                  <span className="property-marker"></span>
                  AuthMethod
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The authentication method that determines how the request is processed. For this step <code>FormsAuthentication</code></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  UserName
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The username of the account with which you want to login.</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name last">
                  <span className="property-marker"></span>
                  Password
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The corresponding password for the account being used.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p>This endpoint will return a <strong>302</strong> response. If your HTTP client supports following
            redirects, enable that option. Otherwise, perform a subsequent <code>GET</code> of the returned
            <code>location</code> header.</p>

          <p>The returned body will contain an HTML <code>&lt;form&gt;</code> element containing either the
            data required to perform MFA validation or the ID token itself. Proceed to the next relevant step
            based on the <code>client_id</code> you submitted with the request.</p>

          <h4>Perform Multi-Factor Authentication Validation</h4>
          <p>The MFA context you need to submit with this request is returned in the body (HTML form) of the
            previous step's response. You can retrieve it using <code>xpath</code> or any similar HTML
            parsing library.</p>

          <div className="code-block">
            <label>XPath Example</label>
            <code>
              xpath 'html/body/form/input[@name="Context"]/@value'
            </code>
          </div>

          <p>You'll use the <code>Context</code> value in the following request.</p>

          <p><span className="http-method post">POST</span> <code className="no-background">https://sts.armor.com/adfs/oauth2/authorize</code></p>

          <h5 className="request-summary">Query String Parameters</h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name first">
                  <span className="property-marker"></span>
                  response_type
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p><em>Use the same value as the previous request:</em> <code>id_token</code></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  response_mode
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p><em>Use the same value as the previous request:</em> <code>form_post</code></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  client_id
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p><em>Use the same value as the previous request.</em></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name last">
                  <span className="property-marker"></span>
                  redirect_uri
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p><em>Use the same value as the previous request.</em></p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className="request-summary">Request Body Schema <span>application/x-www-form-urlencoded</span></h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name first">
                  <span className="property-marker"></span>
                  AuthMethod
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The authentication method that determines how the request is processed. For this step <code>AzureMfaServerAuthentication</code></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  Context
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The base64-encoded, encrypted MFA context retrieved from the previous response.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p>Note that this request completes synchronously whenever MFA validation succeeds or times out.
            The resulting response will either include the ID token or an error message.</p>

          <h4>Retrieve Your ID Token</h4>

          <p>Similar to how you retrieve the MFA context in the previous step, use <code>xpath</code>
            or a similar parser to get the value if the <code>id_token</code> HTML input:</p>

          <div className="code-block">
            <label>XPath Example</label>
            <code>
              xpath 'html/body/form/input[@name="id_token"]/@value'
            </code>
          </div>

          <p>The resulting value is your ID token. You can now use it to make requests to any OAuth2
            endpoint, or use it to request a scoped access token.</p>

          <h3>Requesting a Scoped Access Token</h3>

          <p>Scoped access token generation is handled by the <a href="https://api.armor.com/">api.armor.com</a>&nbsp;
            and requires a valid ID token. To retrieve a scoped access token, make the following API request with
            your ID token:</p>

          <p><span className="http-method post">POST</span> <code className="no-background">https://api.armor.com/auth/token</code></p>

          <h5 className="request-summary">Query String Parameters</h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  scope
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>A space-delimited list of scopes to assign to the access token.</p>
                    <p>A full list of permission scopes are available by going to the&nbsp;
                      <a href="https://amp.armor.com/account/roles">Roles and Permissions Screen</a>&nbsp;
                      in the Armor Management Portal and selecting a role. The scope name will
                      be the "System" name prefixed with <code>*:</code></p>

                    <p>For example, if the system for a given permission is <code>log-management</code>,
                      the corresponding scope is <code>*:log-management</code></p>

                    <p>The list of scopes must always include at least <code>openid</code>, <code>email</code>, and <code>profile</code></p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className="request-summary">Request Headers</h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  authorization
                  <div className="property-required">required</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">string</span>
                    <p>The authorization header including the <code>Bearer</code> scheme and the ID token from above.</p>
                    <p>Example: <code>Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9&hellip;</code></p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className="request-summary">Request Body Schema <span>application/json</span></h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  (None)
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">null</span>
                    <p>The body of this request must be exactly <code>null</code>.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h5 className="request-summary">Response Body Schema <span>application/json</span></h5>

          <table className="property-summary">
            <tbody>
              <tr>
                <td className="property-name first">
                  <span className="property-marker"></span>
                  assertion
                  <div className="property-required">&nbsp;</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">null</span>
                    <p>The resulting scoped access token as a JWT.</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name">
                  <span className="property-marker"></span>
                  scope
                  <div className="property-required">&nbsp;</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">null</span>
                    <p>A space separated list of scopes included on the token. This should be
                      identical to the requested scopes unless the user does not have any permissions
                      applicable to a requested scope, in which case that scope is excluded.</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-name last">
                  <span className="property-marker"></span>
                  token_type
                  <div className="property-required">&nbsp;</div>
                </td>
                <td>
                  <div className="property-definition">
                    <span className="property-type">null</span>
                    <p>The type of token returned in the <code>assertion</code> field. Always <code>jwt-bearer</code>.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p>You can now use the access token from the <code>assertion</code> property of the response, and the ID
            token retrieved in the previous step to make API calls to any OAuth2 scoped token endpoint.</p>

          <h3 id="disabling_mfa">Disabling Multi-Factor Authentication (MFA) for Service Accounts</h3>

          <p>Disabling MFA for service accounts, scripts, and other non-interactive integrations can be done
            by <a href="https://support.armor.com/">creating a ticket</a> requesting MFA be disabled for a
            certain account. Please be sure to provide justification and an administrator's approval in
            the ticket.</p>

        </div>
      </div>
    </div>
  );
};
