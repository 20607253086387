import { RedocRawOptions } from 'redoc';
import { colors } from './colors';

export const redocOptions: RedocRawOptions = {
  scrollYOffset: 75,
  theme: {
    codeSample: {
      backgroundColor: '#313e5f'
    },
    colors: {
      border: {
        dark: colors.gray850,
        light: colors.gray200
      },
      http: {
        delete: colors.magma080,
        get: colors.success,
        patch: colors.magma060,
        post: colors.marine060,
        put: colors.magma040
      },
      primary: {
        main: colors.black
      },
      responses: {
        error: { color: colors.failure, backgroundColor: colors.white },
        success: { color: colors.success, backgroundColor: colors.white }
      },
      tonalOffset: 0.2
    },
    menu: {
      backgroundColor: colors.gray075,
      width: '300px'
    },
    rightPanel: {
      backgroundColor: '#1b253e'
    },
    schema: {
      linesColor: colors.gray100
    },
    typography: {
      code: {
        fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
        fontSize: '14px',
        color: '#05aabe'
      },
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSize: '16px',
      headings: {
        fontFamily: 'armorsans-bold'
      },
      links: {
        color: colors.link
      }
    }
  }
};
