import { A } from "hookrouter";
import * as React from "react";

export const NotFound = () => (
    <div className="bg-img">
        <div className="main container mx-auto text-center pt-12">
            <h1 className="text-4xl pt-8 text-white font-bold">Page Not Found</h1>
            <p className="text-white pt-10 pb-16">Return to the <A href="/">homepage</A> to continue.</p>
        </div>
    </div>
);
